// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: white,
    contrast: (
        500: $dark-primary-text,
    ),
);
$fuse-black: (
    500: black,
    contrast: (
        500: $light-primary-text,
    ),
);
$fuse-navy: (
    50: #ececee,
    100: #c5c6cb,
    200: #9ea1a9,
    300: #7d818c,
    400: #5c616f,
    500: #3c4252,
    600: #353a48,
    700: #2d323e,
    800: #262933,
    900: #1e2129,
    A100: #c5c6cb,
    A200: #9ea1a9,
    A400: #5c616f,
    A700: #2d323e,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);
// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy,
);
@include fuse-color-classes($custom_palettes);
// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
// Force the input field font sizes to 16px
$typography: mat.define-typography-config(
    $font-family: "Muli, Helvetica Neue, Arial, sans-serif",
    $title: mat.define-typography-level(20px, 32px, 600),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
    $input: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!,,,,,,,,,,,,
);
// Setup the typography
@include mat.all-component-typographies($typography);
// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------
// Import app and page component themes here to enable theming for them
@import "src/app/layout/components/toolbar/toolbar.theme";
@import "src/app/main/pages/login/login.theme";
@import "src/app/main/pages/register/register.theme";
@import "src/app/main/pages/reset-password/reset-password.theme";
@import "src/app/main/pages/forgot-password/forgot-password.theme";
@import "src/app/main/pages/lock/lock.theme";
@import "src/app/main/appointments/appointments.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {
    // Layout components

    @include toolbar-theme($theme);
    @include login-theme($theme);
    @include register-theme($theme);
    @include reset-password-theme($theme);
    @include forgot-password-theme($theme);
    @include lock-theme($theme);
    @include appointments-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);
// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette,
    );
    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$yellow-light-theme: mat.define-light-theme(
    $yellow-light-theme-primary-palette,
    $yellow-light-theme-accent-palette,
    $yellow-light-theme-warn-palette
);
// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
    // Generate the Angular Material theme
    @include mat.all-component-themes($yellow-light-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);
    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette,
    );
    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme(
    $blue-gray-dark-theme-primary-palette,
    $blue-gray-dark-theme-accent-palette,
    $blue-gray-dark-theme-warn-palette
);
// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($blue-gray-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);
    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette,
    );
    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);
// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme(
    $pink-dark-theme-primary-palette,
    $pink-dark-theme-accent-palette,
    $pink-dark-theme-warn-palette
);
// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette,
    );
    @include fuse-color-classes($palettes);
}

@mixin table-mobile() {
    width: 100%;
    display: block;
    overflow: hidden;

    tbody,
    thead {
        float: left;
        display: block;
    }

    tr {
        display: block;
        width: 100%;
    }
    tbody {
        width: 70%;
    }

    thead {
        width: 30%;
    }

    tbody td:first-child,
    thead th:first-child {
        padding-left: 8px !important;
    }

    tbody td,
    thead th {
        display: block;
        width: 100%;
        text-align: left !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    thead th {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        // background: #f3f3f3;
    }
}

table.responsive {
    &.force-mobile {
        @include table-mobile();
    }

    @include media-breakpoint("lt-md") {
        @include table-mobile();
    }
}

.cursor {
    cursor: pointer;
}

// body.theme-default  .accent {
//     background: rgb(2,0,36)!important;
//     background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 28%, rgba(3,155,229,1) 100%)!important;
// }
